import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as ROUTES from './constants/routes';
import { IsUserRedirect, ProtectedRoute } from './helpers/routes';
import { useAuthListener } from './hooks';
import { Spinner } from "./Components";
import './app.scss';
import './assets/scss/dashboardStyle.scss';

import { ToastContainer } from "react-toastify";
import jsCookie from "js-cookie";

const Login = React.lazy(() => import('./pages/login/Login'));
const Register = React.lazy(() => import('./pages/register/Register'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const PageRequestAccess = React.lazy(() => import('./pages/page404/PageRequestAccess'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));
const TheLayout = React.lazy(() => import('./containers/TheLayout/TheLayout'));

export default function App() {
    const { user, role } = useAuthListener();
    // Set default Cookie || For development purpose only
    if (process.env.REACT_APP_GIP_USERID_COOKIE) {
        jsCookie.set('gipuserid', process.env.REACT_APP_GIP_USERID_COOKIE);
    }

    return (
        user ?
        user && role !== "" ? 
        <BrowserRouter>
            <React.Suspense fallback={<Spinner />}>
                <Switch>
                    <ProtectedRoute exact user={user} path={ROUTES.DASHBOARD}>
                        <Suspense fallback={<Spinner />}>
                            <TheLayout role={role} />
                        </Suspense>
                    </ProtectedRoute>
                    <ProtectedRoute exact user={user} path={ROUTES.PAST_ORDERS}>
                        <Suspense fallback={<Spinner />}>
                            <TheLayout role={role} />
                        </Suspense>
                    </ProtectedRoute>
                    <ProtectedRoute exact user={user} path={ROUTES.PAYOUT}>
                        <Suspense fallback={<Spinner />}>
                            <TheLayout role={role} />
                        </Suspense>
                    </ProtectedRoute>
                    <ProtectedRoute exact user={user} path={ROUTES.REPORTS}>
                    <Suspense fallback={<Spinner />}>
                            <TheLayout role={role} />
                        </Suspense>
                    </ProtectedRoute>
                    <ProtectedRoute exact user={user} path={ROUTES.REFUND}>
                        <Suspense fallback={<Spinner />}>
                            <TheLayout role={role} />
                        </Suspense>
                    </ProtectedRoute>
                    <ProtectedRoute exact user={user} path={ROUTES.QRCODE}>
                        <Suspense fallback={<Spinner />}>
                            <TheLayout role={role} />
                        </Suspense>
                    </ProtectedRoute>
                    <ProtectedRoute exact user={user} path={ROUTES.ORDER_LOGS}>
                        <Suspense fallback={<Spinner />}>
                            <TheLayout role={role} />
                        </Suspense>
                    </ProtectedRoute>
                    <ProtectedRoute exact user={user} path={ROUTES.BALANCE_AND_DUE_DATES}>
                        <Suspense fallback={<Spinner />}>
                            <TheLayout role={role} />
                        </Suspense>
                    </ProtectedRoute>
                    {
                        role === "superuser" ? 
                        <ProtectedRoute exact user={user} path={ROUTES.USER_MANAGEMENT}>
                            <Suspense fallback={<Spinner />}>
                                <TheLayout role={role} />
                            </Suspense>
                        </ProtectedRoute> :
                        <ProtectedRoute exact user={user} path={ROUTES.USER_MANAGEMENT}>
                            <Suspense fallback={<Spinner />}>
                                <PageRequestAccess />
                            </Suspense>
                        </ProtectedRoute>
                    }
                    <ProtectedRoute exact user={user} path={ROUTES.REGISTER}>
                        <Suspense fallback={<Spinner />}>
                            <Register />
                        </Suspense>
                    </ProtectedRoute>
                    <IsUserRedirect exact user={user} loggedInPath={ROUTES.DASHBOARD} path={ROUTES.Login}>
                        <Suspense fallback={<Spinner />}>
                            <Login />
                        </Suspense>
                    </IsUserRedirect>

                    <Route exact path={ROUTES.ERROR500} name="Page 500" >
                        <Suspense fallback={<Spinner />}>
                            <Page500 />
                        </Suspense>
                    </Route>
                    <Route path={ROUTES.ERROR404} name="Page 404">
                        <Suspense fallback={<Spinner />}>
                            <Page404 />
                        </Suspense>
                    </Route>
                    <Route path={ROUTES.PageRequestAccess} name="Page Access">
                        <Suspense fallback={<Spinner />}>
                            <PageRequestAccess />
                        </Suspense>
                    </Route>
                    <Route name="Page 404" >
                        <Suspense fallback={<Spinner />}>
                            <Page404 />
                        </Suspense>
                    </Route>
                </Switch>
                <ToastContainer />
            </React.Suspense>
        </BrowserRouter> : 
        <BrowserRouter>
            <React.Suspense>
                <Suspense fallback={<Spinner />}>
                    <Spinner />
                </Suspense>
            </React.Suspense>
        </BrowserRouter>
        : 
        <BrowserRouter>
            <React.Suspense>
                <Suspense fallback={<Spinner />}>
                    <Login />
                </Suspense>
            </React.Suspense>
        </BrowserRouter>
    );
}
